<template>
    <div class="component" :class="{'bg-gray-light': !theme || theme == 'default', 'bg-pink': theme == 'pink'}">
        <div class="grid pt-14 md:pt-8">
            <div class="grid-cols-12" v-if="label">
                <h3 class="headline">{{label}} <span class="font-serif">/</span> <span class="text-gray-dark" v-if="labelEnglish">{{labelEnglish}}</span></h3>
            </div>
            <NuxtLink :to="url" class="grid-cols-12 relative group">
                <div class="aspect-[398/250] md:aspect-[1608/718] md:mb-22" :class="`image image-${imageLayout}`">
                    <Media :data="media" class="block h-full"/>
                </div>
                <div class="max-md:-mt-[4.35rem] -mt-[21.75rem] relative z-10 w-full flex flex-col md:flex-row justify-between items-start">
                    <div class="inline-block pr-4 pt-4 md:pt-8 md:pr-19 md:mt-18 max-md:max-w-[86%]" :class="{'bg-gray-light': !theme || theme == 'default', 'bg-pink': theme == 'pink'}">
                        <p
                            class="text-mini transition duration-200 max-md:mb-[0.4rem] mb-[0.3rem] uppercase"
                            :class="{'group-hover:text-green': !theme || theme == 'default', 'group-hover:text-pink-light': theme == 'pink'}"
                        >{{stage}}</p>
                        <h2
                            class="headline-maxi transition duration-200"
                            :class="{'group-hover:text-green': !theme || theme == 'default', 'group-hover:text-pink-light': theme == 'pink'}"
                        >{{title}}</h2>
                        <p
                            class="headline-maxi-serif transition duration-200"
                            :class="{'group-hover:text-green': !theme || theme == 'default', 'group-hover:text-pink-light': theme == 'pink'}"
                        >{{$dateFormat(dateFrom, dateTo)}}</p>
                    </div>

                    <ElementButton title="Læs mere" englishTitle="Read More" class="mt-6 md:mt-[14.75rem] md:mb-20 md:pb-6 md:block md:shrink-0"/>
                </div>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { $dateFormat } = useNuxtApp()

const props = defineProps({
    label: {
        type: String,
    },
    labelEnglish: {
        type: String,
    },
    title: {
        type: String,
        default: 'Hero'
    },
    dateFrom: {
        type: String,
        default: "2024-06-04T07:00:00+00:00"
    },
    dateTo: {
        type: String,
        default: "2024-06-04T07:00:00+00:00"
    },
    stage: {
        type: String,
    },
    media: {
        type: Object,
        default: {
            url: 'https://sydhavnteater:8890/assets/Screenshot-2024-07-01-at-14.12.29.jpg',
            width: 891,
            height: 551,
            mimeType: 'image/jpeg',
            title: 'Hero image'
        }
    },
    imageLayout: {
        type: String,
        default: 'cover'
    },
    theme: {
        type: String,
    },
    url: {
        type: String,
        default: '#'
    }
});
</script>

<style lang="scss" scoped>
.component {
    :deep(.media) {
        @apply flex flex-row;
        
        img {
            @apply h-full w-full;
        }
    }
}

.image {
    &:deep(.media) img {
        @apply object-cover object-center;
    }

    &-left :deep(.media) img {
        @apply object-contain object-left;
    }

    &-right :deep(.media) img {
        @apply object-contain object-right;
    }

    &-center :deep(.media) img {
        @apply object-contain object-center;
    }
}
</style>